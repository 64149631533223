import styled, { keyframes } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

const StyledSpinnerContainer = styled.div.withConfig({
  componentId: "sg1730__sc-1ibs61u-0"
})(["align-items:center;display:flex;justify-content:", ";"], props => props.align);
const rotate = keyframes(["to{transform:rotate(360deg);}"]);
const StyledSpinner = styled.div.withConfig({
  componentId: "sg1730__sc-1ibs61u-1"
})(["animation:", " 1s linear infinite;border-radius:", ";border:2px solid rgba(0,0,0,0.1);border-right-color:", ";display:inline-block;flex:0 0 auto;height:", ";vertical-align:text-bottom;width:", ";"], rotate, tokens.radius.circle, tokens.color.primary, props => props.size === 'sm' ? tokens.space.md : tokens.space.lg, props => props.size === 'sm' ? tokens.space.md : tokens.space.lg);

export { StyledSpinner, StyledSpinnerContainer };
